
export default
	inject: textColor: default: ''

	props:
		section: Object
		hasBgImage: Boolean

	computed:
		computedTextColor: -> @textColor || @section.textColor


