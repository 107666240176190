
import Slide from './slide'
import { padNum } from '~/services/helpers'
export default
	components: { Slide }
	props: card: Object

	data: ->
		active: 0
		autoPlayInterval: null
		autoplayDelay: 3000
		offsetPage: 0

	computed:
		classes: -> [@card?.size || "medium", "hide-mobile" if !@card?.slides?.length]
		pageOffset: -> "margin-left: #{@offsetPage}px"
		activePage: -> padNum @active + 1
		hasPagination: -> @card?.slides?.length > 1

	watch: active: (active) -> @getPageOffset()

	mounted: ->
		window.addEventListener 'resize', @getPageOffset
		return unless @card?.autoPlay
		@autoplayInterval = setInterval @nextSlide, @autoplayDelay

	beforeDestroy: -> window.removeEventListener 'resize', @getPageOffset

	methods:
		goTo: (index) ->
			clearInterval @autoplayInterval
			@active = index
			@autoplayInterval = setInterval @nextSlide, @autoplayDelay

		nextSlide: -> @active = (@active+1) % @card.slides?.length

		getPageOffset: ->
			return unless @hasPagination and ref = @$refs.dot?[@active]
			@offsetPage = ref.offsetLeft

