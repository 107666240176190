import { render, staticRenderFns } from "./slide.vue?vue&type=template&id=3fc02029&scoped=true&lang=pug"
import script from "./slide.vue?vue&type=script&lang=coffee"
export * from "./slide.vue?vue&type=script&lang=coffee"
import style0 from "./slide.vue?vue&type=style&index=0&id=3fc02029&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc02029",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/site/components/globals/craft-visual.coffee').default,ResponsiveCraftVisual: require('/opt/build/repo/site/node_modules/@bkwld/cloak/components/responsive-craft-visual.vue').default})
