
import SlideSection from './slide-section'
export default
	components: { SlideSection }
	props:
		slide: Object
		size: String
		hasPagination: Boolean

	computed:
		sizes: -> switch @size
			when "small" then "(min-width: 768px) 320px, (max-width: 767px) #{767/2}px"
			when "medium" then "(min-width: 768px) 438px, (max-width: 767px) 767px"
			when "large" then "(min-width: 768px) 672px, (max-width: 767px) 767p"

		isBackgroundImage: -> @slide?.image?.length && (!@slide.containImage)
		isBackgroundVideo: -> @slide?.video?.length && (!@slide.containImage)
		isBackgroundAsset: -> @isBackgroundImage || @isBackgroundVideo

		isBackgroundColor: -> (@size != "large" && @slide.containImage) || !@slide?.image?.length

		youtubeVideoID: -> @$getYoutubeVideoID(@slide.url) if @slide.url

		url: -> if @youtubeVideoID then undefined else @slide.url

		overlayIntensity: -> @slide.overlayIntensity if @slide.overlayIntensity != 'no-overlay'

		bkgClass: -> [
			"overlay-intensity-#{@overlayIntensity}" if @overlayIntensity
		]


	methods:
		openModal: ->
			return unless @youtubeVideoID || @slide.modalVideo
			mp4Video = @slide.modalVideo?[0]
			if mp4Video then @$store.commit 'videoModal/play', {video: mp4Video.videoDesktop?[0] || mp4Video.videoMobile?[0], title: mp4Video.title, subtitles: mp4Video.subtitles, libraryVideoId: mp4Video.id }
			else if @youtubeVideoID then @$store.commit 'videoModal/play', @slide.url

